<template>
  <div>
    <!-- pic0DAEBAEEB373ABBE1667735714F546A1.jpg -->
    <img class="rounded img-large" src="">
    <p>
      Pod 7, the last of the mysterious LEGO pods, is set to land on Earth somewhere along the US west coast. Scientists from the Bradford Rant Institute have speculated that California will be the most likely location, due to high concentration of LEGO in the area.
    </p>
    <p>
      "We don't know where the pod will land," said Professor Gunther Wendt. "But we believe it must be a LEGO store or related location."
    </p>
    <p>
      Pod 7 has special importance because it brings with it the final four letters needed to reveal the mystery of this phenomenon.
    </p>
    <p>
       "All of the fans who have been following the pods and translating the data can finally complete the message," said Professor Wendt. "As soon as they find the letters they should combine them with the letters from the other pod data. We believe when these are placed in the correct order, a message from the minifigs will be revealed!"
    </p>
    <p>
      To see how you can find the final LEGO pod and help reveal the mystery, visit
      <a href="http://www.bradfordrant.org/">www.Bradfordrant.org</a>.
    </p>
  </div>
</template>
